<template>
  <section>
    <Button @click="toggleModal" :text="$t('add-member.add-member')" :type="'zoom'"/>
    <div class="modal is-active" v-if="showModal">
      <div class="modal-background" @click="toggleModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('add-member.add-members') }}</p>
          <button class="delete" @click="toggleModal" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <b-tabs position="is-centered" class="block" v-model="activeTab">
            <b-tab-item :label="$t('add-member.single-member')">
              <div>
                <b-field :label="$t('profile.attention')">
                  <b-input v-model="curr_member.attention" placeholder="Anrede"></b-input>
                </b-field>
                <b-field :label="$t('profile.name')">
                  <b-input v-model="curr_member.firstname" placeholder="Vorname"></b-input>
                </b-field>
                <b-field :label="$t('profile.surname')">
                  <b-input v-model="curr_member.lastname" placeholder="Nachname"></b-input>
                </b-field>
                <b-field :label="$t('profile.mail')">
                  <b-input v-model="curr_member.u" type="email" required
                  :validation-message="$t('add-member.validation-message')"
                  :placeholder="$t('profile.mail')">
                  </b-input>
                </b-field>
                <b-field :label="$t('add-member.language')">
                  <b-select value="de" v-model="curr_member.lang">
                    <option value="de">{{ $t('add-member.german') }}</option>
                    <option value="en">{{ $t('add-member.english') }}</option>
                  </b-select>
                </b-field>
              </div>
            </b-tab-item>
            <b-tab-item :label="$t('add-member.csv-import-title')">
              <div>
                <h3>{{ $t('add-member.csv-import-title') }}</h3>
                <Upload class="upload" v-model="csv" :mimetype="'text/csv'" :multi="true" />
              </div>
              <b-field :label="$t('add-member.language')">
                <b-select value="de" v-model="csvLang">
                  <option value="de">{{ $t('add-member.german') }}</option>
                  <option value="en">{{ $t('add-member.english') }}</option>
                </b-select>
              </b-field>
            </b-tab-item>
          </b-tabs>
        </section>
        <footer class="modal-card-foot">
          <Button @click="toggleModal" :text="$t('common.cancel')" :type="'zoom'"/>
          <Button @click="addMember(true);" :text="$t('common.save')" :type="'zoom'"
          v-if="activeTab === 0" />
          <Button @click="addMember(false);" :text="$t('add-member.save-and-new')" :type="'zoom'"
          v-if="activeTab === 0" />
          <Button :text="$t('add-member.import')" @click="importCSV()"
          v-if="activeTab === 1"/>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Upload from '../Common/Upload.vue';
import Button from '../Common/Button.vue';

export default {
  props: ['useCase'],
  components: {
    Button,
    Upload,
  },
  data() {
    return {
      showModal: false,
      csv: [],
      activeTab: 0,
      csvLang: 'de',
    };
  },
  computed: {
    curr_member() {
      return this.$store.getters['Groups/get_member'];
    },
    group_assignments() {
      return this.$store.getters['Groups/get_assignments'];
    },
  },
  methods: {
    async importCSV() {
      let r;
      const sgGroup = this.$store.getters['Groups/get_sg'];
      const params = {
        csv: this.csv[0],
        lang: this.csvLang,
        group: {
          id: sgGroup.id,
          client_id: sgGroup.client_id,
        },
      };
      console.log(params);
      if (this.csv[0] !== undefined) {
        r = await this.$store.dispatch('Uploads/importCSV', params);
        if (r) {
          for (let i = 0; i < r.data.length; i += 1) {
            this.$store.commit('Groups/set_member', r.data[i]);
          }
        }
      }
    },
    toggleModal() {
      this.$store.commit('Groups/new_user');
      this.showModal = !this.showModal;
    },
    addMember(closeModal) {
      if (this.curr_member.firstname && this.curr_member.u && this.curr_member.lastname) {
        if (this.useCase === 'admin') this.$store.commit('Groups/set_sg', null);
        this.$store.dispatch('Groups/add_member').then((user) => {
          this.$emit('added');
          if (this.group_assignments.length !== 0 && this.useCase !== 'admin') {
            this.$buefy.dialog.confirm({
              title: this.$t('add-member.assignments'),
              message: this.$t('add-member.assign-text'),
              confirmText: this.$t('common.yes'),
              cancelText: this.$t('common.no'),
              type: '',
              hasIcon: true,
              onConfirm: () => { // Loop to add every Assignment-Course to the user
                for (let i = 0; i < this.group_assignments.length; i += 1) {
                  const payload = {
                    userId: user.id,
                    assignmentId: this.group_assignments[i].id,
                  };
                  this.$store.dispatch('Assignment/add_one_assignment', payload);
                }
              },
            });
          }
          this.$buefy.toast.open({
            message: this.$t('add-member.add-member-success'),
            type: 'is-success',
          });
        });
        if (closeModal) {
          this.toggleModal();
        }
        this.$store.commit('Groups/new_user');
      } else {
        this.$buefy.toast.open({
          message: this.$t('add-member.add-member-error'),
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

label {
  float: left;
}

.upload {
  display: block;
  margin: 2rem;
}

.modal-card-body {
  height: 30rem;
}

.control-select {
  width: 5.3rem;
}
</style>
