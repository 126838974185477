<template>
  <section>
    <Button @click="toggleModal" :text="$t('add-group.add-group')" :type="'zoom'" />
    <div class="modal is-active" v-if="showModal">
      <div class="modal-background" @click="toggleModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('add-group.add-group') }}</p>
          <button class="delete" @click="toggleModal" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <input v-model="new_group_name" class="input"
          :placeholder="$t('add-group.name-group')" type="text">
        </section>
        <footer class="modal-card-foot">
          <Button @click="toggleModal" :text="$t('common.cancel')" :type="'zoom'"/>
          <Button @click="addGroup()" :text="$t('add-group.confirm-group')" :type="'zoom'"/>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '../Common/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      showModal: false,
      new_group_name: null,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    addGroup() {
      if (this.new_group_name) {
        this.$emit('addGroup', this.new_group_name);
        this.$buefy.toast.open({
          message: this.$t('add-group.add-group-success'),
          type: 'is-success',
        });
        this.toggleModal();
        this.new_group_name = null;
      } else {
        this.$buefy.toast.open({
          message: this.$t('add-group.add-group-error'),
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.cancel-button {
  color: rgba(53, 61, 64, 1);
  font-weight: 600;
  font-size: 1em;
}
</style>
