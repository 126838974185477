<!-- eslint-disable max-len -->

<template>
  <section class="management">
    <Navbar />
    <section class="hero fixed-nav responsive-pad ">
      <div class="hero-head pt-4 pb-4">
        <p class="title">
          Bereitschaftsplan
        </p>
      </div>
      <div class="columns">
        <div class="column" v-for="dow in standbys" :key="dow.id">
          {{ dow.day }} <br/>
          <div v-for="lesson in dow.lessons" :key="lesson.id">
            {{ lesson.name }} <br/>
            <b-taginput
                v-model="lesson.teachers"
                ellipsis
                :data="fl"
                @typing="getFilteredTs"
                autocomplete
                field="lastname"
                icon="label"
                placeholder="Lehrer hinzufügen"
                aria-close-label="Lehrer löschen">
            </b-taginput>
          </div>
        </div>
      </div>

      <div class="">
        <div class="controls">
          <Button :disabled="false" :type="'zoom'" @click="save_standby" :text="'Änderungen Speichern'" />
        </div>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable arrow-body-style */

import 'vue2-datepicker/index.css';
import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';
import Button from '../components/Common/Button.vue';
import formatting from '../mixins/formatting';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },
  created() {
    this.$store.dispatch('Teachers/load');
  },
  mixins: [formatting],
  data() {
    return {
      fl: [],
      test: new Date(),
      days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'],
      selected_day: 3,
      changed: true,
    };
  },
  computed: {
    t() {
      return this.$store.getters['Teachers/get_teachers'];
    },
    standbys() {
      return this.$store.getters['Account/standbys'];
    },
    classes() {
      return this.$store.getters['Account/classes'];
    },
    hours() {
      return this.$store.getters['Account/hours'];
    },
    search_teachers() {
      if (this.$store.getters['Teachers/get_search_list'] === []) {
        return this.teachers;
      }
      return this.$store.getters['Teachers/get_search_list'];
    },
  },
  methods: {
    getFilteredTs(text) {
      this.fl = this.t.filter((option) => {
        return option.lastname
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0;
      });
    },
    change() {
      this.changed = true;
    },
    add_missing(teacher, dayIndex, rowIndex) {
      this.changed = true;
      this.agreements[dayIndex].lessons[rowIndex].missing = {
        name: teacher.fullName,
        id: teacher.id,
      };
      console.log(teacher);
    },
    add_sub(teacher, dayIndex, rowIndex) {
      this.changed = true;
      this.agreements[dayIndex].lessons[rowIndex].substitute = {
        name: teacher.fullName,
        id: teacher.id,
      };
    },
    del_agreements(dayIndex, rowIndex) {
      this.agreements[dayIndex].lessons.splice(rowIndex, 1);
      this.save_agreements();
    },
    add_agreement(dayIndex) {
      const lesson = {
        hour: {
          name: '',
          time: '',
        },
        class: '',
        subject: '',
        comment: '',
        missing: {
          name: '',
          id: '',
        },
        substitute: {
          name: '',
          id: '',
        },
        timeframe: [],
      };
      this.agreements[dayIndex].lessons.push(lesson);
      this.save_agreements();
    },
    save_standby() {
      this.$store.dispatch('Account/save_standby').then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Erfolgreich gespeichert',
            type: 'is-success',
          });
          this.changed = false;
        } else {
          this.$buefy.toast.open({
            message: 'Etwas ist schiefgelaufen...',
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.agreements {
  table:hover {
    cursor: pointer;
  }

  .table {
    Button {
      font-size: 0.9rem;
      height: 2.2rem;
      padding: 0.6rem;
    }

    tr.is-selected {
      background-color: var(--secondary-color) !important;
    }

    tr {
      cursor: pointer;
    }
  }

  .clickable:hover {
    cursor: pointer !important;
  }
}
</style>
