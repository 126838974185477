<!-- eslint-disable max-len -->

<template>
  <section class="management">
    <Navbar />
    <section class="hero fixed-nav responsive-pad ">
      <div class="hero-head pt-4 pb-4">
        <p class="title">
          Abwesenheiten
        </p>
      </div>
      <b-field :label="$t('teacher-management.search')">
        <b-autocomplete :key="this.loading"
        v-model="name"
        :placeholder="$t('teacher-management.search-placeholder')"
        :keep-first="true"
        :open-on-focus="true"
        :data="search_teachers"
        @select="select($event)"
        field="fullName"
        :empty="[]"
        :clearable="true"
        @input="search(name)">
          <template #empty>Keine Ergebnisse</template>
        </b-autocomplete>
      </b-field>
      <div class="modal is-active" v-if="edit_modal && name != ''">
        <div class="modal-background" @click="reset_selected"></div>
        <div class="modal-card">
          <header class="modal-card-head has-text-centered">
            <p class="title card-title">{{ selected_teacher.fullName }}</p>
          </header>
          <section class="modal-card-body">
            <div>
              <b-field :label="this.$t('teacher-management.search-label')">
                <b-datepicker
                  v-model="dates"
                  :show-week-number="false"
                  :locale="'de-DE'"
                  :placeholder="this.$t('teacher-management.click-to-select')"
                  icon="calendar-today"
                  trap-focus
                  range>
                </b-datepicker>
              </b-field>
            </div>
            <div class="mt-5">
              <b-field :label="'Grund der Abwesenheit'">
                <b-dropdown aria-role="list" v-model="selected_reason">
                  <template #trigger="{ active }">
                    <b-button :label="selected_reason" class="m-0 mt-2" type="is-primary"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
                  </template>
                  <b-dropdown-item value="Krank" aria-role="listitem">Krank</b-dropdown-item>
                  <b-dropdown-item value="Beurlaubt" aria-role="listitem">Beurlaubt</b-dropdown-item>
                  <b-dropdown-item value="Fahrt" aria-role="listitem">Fahrt</b-dropdown-item>
                </b-dropdown>
              </b-field>
              <b-field label="mit Klasse">
                <b-input value="" v-model="absent_with"></b-input>
              </b-field>
            </div>
            <div v-if="is_deletable" class="mt-5">
              <b-field :label="'Aktionen'">
                <Button :text="'Eintrag entfernen'" @click="del_entry()" />
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot">
            <Button :text="$t('common.cancel')" :type="'zoom'"
            @click="reset_selected" />
            <Button :text="$t('common.save')" :type="'zoom'"
            @click="add_absent_teacher" />
          </footer>
        </div>
      </div>
      <div class="hero-body p-0 pt-5">
        <div v-if="absent_teachers.length != 0">
          <p class="subtitle">Abwesende Lehrer</p>
          <p class="edit-info">Doppelklicken zum bearbeiten</p>
          <b-table class="mt-1"
            @dblclick="edit_teacher($event)"
            :data="absent_teachers"
            :row-class="(row, index) => !row.isAbsent && 'grayed-out'"
            :bordered="true"
            :striped="false"
            :narrowed="false"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="true"
            :paginated="true"
            :per-page="10"
            :selected.sync="table_selected">
            <b-table-column field="fullName" label="Name" v-slot="props">
                {{ props.row.fullName }}
            </b-table-column>
            <b-table-column field="absent" label="vom" sortable v-slot="props">
                {{ dateFormat(props.row.absent[0]) }}
            </b-table-column>
            <b-table-column field="absent" label="bis zum" sortable v-slot="props">
                {{ dateFormat(props.row.absent[1]) }}
            </b-table-column>
            <b-table-column field="absent_reason" label="Grund der Abwesenheit" v-slot="props">
                {{ props.row.absent_reason }}
            </b-table-column>
          </b-table>
          <Button :text="'Plan aktualisieren'" :disabled="disable_button" @click="make_plan()"/>
        </div>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable arrow-body-style */

import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';
import Button from '../components/Common/Button.vue';
import formatting from '../mixins/formatting';

export default {
  components: {
    Navbar,
    Footer,
    Button,
  },

  mixins: [formatting],

  data() {
    return {
      name: '',
      selected_teacher: '',
      absent_with: '',
      dates: [],
      selected_reason: 'Krank',
      loading: false,
      edit_modal: false,
      table_selected: {},
      disable_button: false,
    };
  },

  created() {
    this.$store.dispatch('Teachers/load');
  },

  computed: {
    teachers() {
      return this.$store.getters['Teachers/get_teachers'];
    },
    search_teachers() {
      if (this.$store.getters['Teachers/get_search_list'] === []) {
        return this.teachers;
      }
      return this.$store.getters['Teachers/get_search_list'];
    },
    absent_teachers() {
      return this.$store.getters['Teachers/absent_teachers'];
    },
    currently_absent_teachers() {
      return this.$store.getters['Teachers/get_currently_absent_teachers'];
    },
    teacher_selected() {
      if (this.selected_teacher === '') {
        return false;
      }
      return true;
    },
    is_deletable() {
      return this.selected_teacher.absent.length !== 0;
    },
    date_selected() {
      if (this.dates.length === 0) {
        return false;
      }
      return true;
    },
    button_label() {
      if (this.selected_reason === '') {
        return this.selected_reason;
      }
      return 'Grund der Abwesenheit';
    },
  },

  methods: {
    make_plan() {
      this.disable_button = true;
      this.$store.dispatch('Plans/make_plan').then((response) => {
        console.log(response);
        this.$router.push('/dashboard');
      });
    },
    search() {
      if (this.name === '') return;
      this.$store.dispatch('Teachers/search_teacher', this.name);
    },
    select(teacher) {
      this.loading = true;
      this.selected_teacher = teacher;
      this.name = teacher.name;
      this.edit_modal = true;
    },
    reset_selected() {
      this.loading = true;
      this.selected_teacher = '';
      this.name = '';
      this.dates = [];
      this.selected_reason = 'Krank';
      this.absent_with = '';
      this.teacher_selected = false;
      this.edit_modal = false;
      this.loading = false;
    },
    edit_teacher(teacher) {
      this.selected_teacher = teacher;
      this.dates[0] = new Date(teacher.absent[0]);
      this.dates[1] = new Date(teacher.absent[1]);
      this.selected_reason = teacher.absent_reason;
      this.absent_with = teacher.absent_with;
      this.name = teacher.name;
      this.edit_modal = true;
    },
    del_entry() {
      this.loading = true;
      this.$store.dispatch('Teachers/del_absent_entry', this.selected_teacher).then((r) => {
        if (r) {
          this.$buefy.toast.open({
            message: 'Eintrag entfernt!',
            type: 'is-success',
          });
          this.$store.dispatch('Teachers/load');
          this.reset_selected();
        } else {
          this.$buefy.toast.open({
            message: 'Etwas ist schiefgelaufen...',
            type: 'is-danger',
          });
        }
      });
      this.reset_selected();
    },
    add_absent_teacher() {
      if (this.date_selected) {
        this.loading = true;
        this.selected_teacher.absent = this.dates;
        this.selected_teacher.absent_reason = this.selected_reason;
        this.selected_teacher.absent_with = this.absent_with;
        this.$store.dispatch('Teachers/add_absent_teacher', this.selected_teacher).then((r) => {
          if (r) {
            this.$buefy.toast.open({
              message: 'Abwesenheit eingetragen',
              type: 'is-success',
            });
            this.$store.dispatch('Teachers/load');
            this.reset_selected();
          } else {
            this.$buefy.toast.open({
              message: 'Etwas ist schiefgelaufen...',
              type: 'is-danger',
            });
          }
        });
      } else {
        this.$buefy.toast.open({
          message: 'Bitte geben Sie ein Datum an.',
          type: 'is-danger',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.management {
  font-family: "Roboto";
  margin-top: 3.899rem;
  .hero-body {
    min-height: 20rem !important;
  }
  .save-absense {
    float: right !important;
  }
  .edit-info {
    margin-top: -1rem;
  }
  .modal {
    .modal-background {
    background: rgba(0, 0, 0, 0.319) !important;
    }
    .modal-card-body {
      min-height: 30rem;
    }
  }
  .grayed-out {
    opacity: 0.5;
  }
  .table {
    tr.is-selected {
      background-color: var(--secondary-color)!important;
    }
    tr {
      cursor: pointer;
    }
  }
}
</style>
