<!-- eslint-disable max-len -->

<template>
  <section class="management">
    <Navbar />
    <section class="hero custom-height fixed-nav responsive-pad ">
      <div class="hero-head pt-4 pb-4">
        <p class="title">
          Vereinbarungen zur Vertretung
        </p>
      </div>
      <div class="hero-body pt-0 pl-0">
        <b-tabs v-model="selected_day" class="tab-custom">
          <b-tab-item v-for="(day, i) in days" :key="i" :label="day">
            <div>
              <b-table class="mt-1"
                :data="agreements[selected_day].lessons"
                :bordered="true"
                :striped="false"
                :narrowed="false"
                :hoverable="true"
                :loading="false"
                :focusable="false"
                :mobile-cards="true"
                :paginated="false">
                <b-table-column field="class" label="Klasse" sortable v-slot="props">
                  <b-dropdown aria-role="list" v-model="props.row.class"
                  :scrollable="true" :max-height="400">
                    <template #trigger="{ active }">
                      <b-button :label="props.row.class" type="is-primary"
                      :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item :value="klasse" @click="change" v-for="klasse in classes" :key="klasse"
                    aria-role="listitem">{{ klasse }}</b-dropdown-item>
                  </b-dropdown>
                </b-table-column>
                <b-table-column field="hour" label="Stunde" sortable v-slot="props">
                  <b-dropdown aria-role="list" v-model="props.row.hour"
                  :scrollable="true" :max-height="400">
                    <template #trigger="{ active }">
                      <b-button :label="props.row.hour.name" type="is-primary"
                      :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>
                    <b-dropdown-item @click="change" v-for="hour in hours" :key="hour.name" :value="hour"
                    aria-role="listitem">{{ hour.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-table-column>
                <b-table-column field="missing" label="fehlt" sortable v-slot="props">
                  <b-autocomplete
                    v-model="props.row.missing.name"
                    :placeholder="$t('teacher-management.search-placeholder')"
                    :keep-first="true"
                    :open-on-focus="true"
                    :data="get_teachers"
                    field="fullName"
                    :empty="[]"
                    @input="search(props.row.missing.name)"
                    @select="add_missing($event, i, props.index)">
                      <template #empty>Keine Ergebnisse</template>
                    </b-autocomplete>
                </b-table-column>
                <b-table-column field="substitute" label="vertritt" sortable v-slot="props">
                  <b-autocomplete
                    v-model="props.row.substitute.name"
                    :placeholder="$t('teacher-management.search-placeholder')"
                    :keep-first="true"
                    :open-on-focus="true"
                    :data="get_available_teachers"
                    field="fullName"
                    :empty="[]"
                    @input="search(props.row.substitute.name)"
                    @select="add_sub($event, i, props.index)">
                      <template #empty>Keine Ergebnisse</template>
                    </b-autocomplete>
                </b-table-column>
                <b-table-column field="comment" label="Kommentar" sortable v-slot="props">
                  <input @input="change" v-model="props.row.comment" class="input" type="text" placeholder="Kommentar">
                </b-table-column>
                <b-table-column field="timeframe" label="Zeitraum" sortable v-slot="props">
                  <date-picker
                      range
                      :lang="lang"
                      type="date"
                      format="DD.MM.YYYY"
                      placeholder="Abwesenheit"
                      range-separator="->"
                      v-model="props.row.timeframe"
                      valueType="format">
                  </date-picker>
                </b-table-column>
                <b-table-column label="Aktionen" v-slot="props">
                  <b-dropdown class="clickable" aria-role="list">
                    <template #trigger="">
                      <span class="icon-frame clickable">
                        <font-awesome-icon :icon="['fa', 'ellipsis-h']" />
                      </span>
                    </template>
                    <b-dropdown-item @click="del_agreements(i, props.index)">
                      <font-awesome-icon :icon="['fa', 'trash']" />
                      {{ $t('common.delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-table-column>
              </b-table>
              <div class="controls">
                <Button :disabled="!changed" :type="'zoom'" @click="save" :text="'Änderungen Speichern'" />
                <Button :type="'zoom'" @click="add_agreement(i)" :text="'Neue Vereinbarung'" />
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
/* eslint-disable arrow-body-style */

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Navbar from '../components/Common/Navbar.vue';
import Footer from '../components/Common/Footer.vue';
import Button from '../components/Common/Button.vue';
import formatting from '../mixins/formatting';

export default {
  components: {
    DatePicker,
    Navbar,
    Footer,
    Button,
  },
  created() {
    this.$store.dispatch('Teachers/load');
  },
  mixins: [formatting],
  data() {
    return {
      test: new Date(),
      days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'],
      selected_day: 3,
      changed: false,
      lang: {
        formatLocale: {
          // MMMM
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          // MMM
          monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          // dddd
          weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          // ddd
          weekdaysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
          // dd
          weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 1,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
        },
      },
    };
  },
  computed: {
    agreements() {
      return this.$store.getters['Account/agreements'];
    },
    classesdyn() {
      return this.$store.getters['Account/classes'];
    },
    hours() {
      const h = [{ value: 'HU1', name: 'HU1' },
        { name: 'HU2', time: 'HU2' },
        { name: '1', time: '1' },
        { name: '2', time: '2' },
        { name: '3', time: '3' },
        { name: '4', time: '4' }];

      return h;
      // return this.$store.getters['Account/hours'];
    },
    classes() {
      const h = ['1a', '2a', '2a/2b', '3a', '4a', '4a/4b', '5a', '6a', '6a/6b', '7a', '8a',
        '1b', '2b', '3b', '4b', '5b', '6b', '7b', '8b',
        '1c', '2c', '3c', '4c', '5c', '6c', '7c', '8c'];

      return h;
      // return this.$store.getters['Account/hours'];
    },

    search_teachers() {
      // if (this.$store.getters['Teachers/get_search_list'] === []) {
      //   return this.teachers;
      // }
      return this.$store.getters['Teachers/get_search_list'];
    },
  },
  methods: {
    df(d) {
      const r = [];
      console.log('DATES1: ', d);
      r[0] = new Date(d[0]);
      r[1] = new Date(d[1]);
      console.log('DATES2: ', r);
      return r;
    },
    search(name) {
      if (name === '') return;
      this.$store.dispatch('Teachers/search_teacher', name);
    },
    change() {
      this.changed = true;
    },
    add_missing(teacher, dayIndex, rowIndex) {
      this.changed = true;
      this.agreements[dayIndex].lessons[rowIndex].missing = {
        name: teacher.fullName,
        id: teacher.id,
      };
      console.log(teacher);
    },
    add_sub(teacher, dayIndex, rowIndex) {
      this.changed = true;
      this.agreements[dayIndex].lessons[rowIndex].substitute = {
        name: teacher.fullName,
        id: teacher.id,
      };
    },
    del_agreements(dayIndex, rowIndex) {
      this.agreements[dayIndex].lessons.splice(rowIndex, 1);
      this.save();
    },
    add_agreement(dayIndex) {
      const lesson = {
        hour: {
          name: '',
          time: '',
        },
        class: '',
        subject: '',
        comment: '',
        missing: {
          name: '',
          id: '',
        },
        substitute: {
          name: '',
          id: '',
        },
        timeframe: [
        ],
      };
      this.agreements[dayIndex].lessons.push(lesson);
      this.save();
    },
    save() {
      this.$store.dispatch('Account/save_agreements').then((response) => {
        if (response) {
          this.$buefy.toast.open({
            message: 'Erfolgreich gespeichert',
            type: 'is-success',
          });
          this.changed = false;
        } else {
          this.$buefy.toast.open({
            message: 'Etwas ist schiefgelaufen...',
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.management {

  table:hover {
    cursor: pointer;
  }

  .table {
    Button {
      font-size: .9rem;
      height: 2.2rem;
      padding: .6rem;
    }
    tr.is-selected {
      background-color: var(--secondary-color)!important;
    }
    tr {
      cursor: pointer;
    }
  }
  .clickable:hover {
    cursor: pointer !important;
  }
  .custom-height {
    min-height: 100vh;
    ;
  }
}
</style>
