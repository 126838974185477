<template>
  <section class="add-customer">
    <Button v-if="!edit" @click="toggleModal" :text="$t('add-client.new-client')" :type="'zoom'" />
    <Button v-else @click="toggleModal" :text="$t('add-client.edit')" :type="'zoom'" />
    <div class="modal is-active" v-if="showModal">
      <div class="modal-background" @click="toggleModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="!edit" class="modal-card-title">{{ $t('add-client.add-client') }}</p>
          <p v-else class="modal-card-title">{{ $t('add-client.edit-client') }}</p>
          <button class="delete" @click="toggleModal" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div>
            <b-field :label="$t('add-client.client-name')">
              <b-input type="text" v-model="client.name"
              placeholder="Name">
              </b-input>
            </b-field>
            <b-field :label="$t('add-client.login-parameter')">
              <b-input type="text" v-model="client.login_param"
              :placeholder="$t('add-client.login-placeholder')">
              </b-input>
            </b-field>
            <hr>
            <b-field :label="$t('add-client.color-accent')" class="inline-field color-field">
              <b-input type="color" v-model="client.pcolor"></b-input>
            </b-field>
            <b-field :label="$t('add-client.color-main')" class="inline-field color-field">
              <b-input type="color" v-model="client.scolor"></b-input>
            </b-field>
            <br>
            <b-field :label="$t('add-client.text-color-accent')" class="inline-field color-field">
              <b-input type="color" v-model="client.pcolortext"></b-input>
            </b-field>
            <b-field :label="$t('add-client.text-color-main')" class="inline-field color-field">
              <b-input type="color" v-model="client.scolortext"></b-input>
            </b-field>
            <div>
              <b-tabs position="is-left" size="is-medium"
              class="tab is-centered preview-tab" :style="`--pcolor: ${client.pcolor};
              --pcolortext: ${client.pcolortext};`">
                <b-tab-item :label="$t('add-client.test-text')">
                  <div class="preview"
                  :style="{ 'background-color': client.scolor, 'color': client.scolortext }">
                    {{ $t('add-client.test-text') }}
                  </div>
                </b-tab-item>
                <b-tab-item label="Buttons">
                  <button class="test-button"
                  :style="`--pcolor: ${client.pcolor}; --scolor: ${client.scolor};
                  --scolortext: ${client.scolortext};`">
                  {{ $t('add-client.test-text') }}</button>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
          <hr>
          <b-field :label="$t('add-client.logo')" class="inline-field upload">
            <Upload v-model="logo" :mimetype="'image/*'" :multi="'false'" />
            <img v-if="client.logo" :src="client.logo">
          </b-field>
          <div>
            <hr>
            <p>Dashboard</p>
            <b-dropdown v-model="selected_lang">
              <template #trigger="{ active }">
                <b-button :label="select_label" type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
              <b-dropdown-item v-for="(lang, i) in client.dashboard" :key="i" :value="lang">
                {{ lang.name }}
              </b-dropdown-item>
              <b-dropdown-item @click="addLang" disabled>
                Neue Sprache
              </b-dropdown-item>
            </b-dropdown>
            <div v-if="selected_lang !== ''">
              <b-field :label="$t('add-client.dashboard-title')">
                <b-input type="text" v-model="selected_lang.dbline"></b-input>
              </b-field>
              <b-field :label="$t('add-client.dashboard-text')">
                <b-input type="textarea" v-model="selected_lang.dbtext"></b-input>
              </b-field>
              <b-field :label="$t('add-client.dashboard-video-link')">
                <b-input type="text" v-model="selected_lang.videolink"></b-input>
              </b-field>
              <b-field :label="$t('add-client.video-poster')" class="inline-field upload">
                <Upload v-model="videoposter" :mimetype="'image/*'" :multi="'false'" />
                <img v-if="selected_lang.videoposter" :src="selected_lang.videoposter">
              </b-field>
            </div>
            <hr>
            <b-field :label="$t('add-client.footer-text')">
              <b-input type="textarea" v-model="client.footer_txt"></b-input>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <Button :text="$t('common.cancel')" :type="'zoom'"
          @click="toggleModal" />
          <Button :text="$t('add-client.save-client')" :type="'zoom'"
          @click="addClient()" />
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Upload from '../Common/Upload.vue';
import Button from '../Common/Button.vue';

export default {
  components: {
    Upload,
    Button,
  },

  props: ['edit', 'editClient'],

  created() {
    if (!this.edit) {
      this.$store.commit('Clients/new_client');
    }
  },

  data() {
    return {
      showModal: false,
      logo: [],
      videoposter: [],
      selected_lang: '',
    };
  },
  computed: {
    pcolor_text_test() {
      return this.new_client_pcolor_text;
    },
    client() {
      if (this.edit) {
        return this.$store.getters['Clients/get_clients'][this.editClient];
      }
      return this.$store.getters['Clients/get_new_client'];
    },
    select_label() {
      if (this.selected_lang === '') return 'Sprache';
      return this.selected_lang.name;
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    async addClient() {
      let r;
      if (this.logo[0] !== undefined) {
        r = await this.$store.dispatch('Uploads/upload_files', this.logo[0]);
        this.client.logo = r;
      }
      if (this.videoposter[0] !== undefined) {
        r = await this.$store.dispatch('Uploads/upload_files', this.videoposter[0]);
        this.client.videoposter = r;
      }
      let payload = {
        new_client: true,
      };
      if (this.edit) {
        payload = {
          new_client: false,
          index: this.editClient,
        };
      }
      this.$store.dispatch('Clients/save', payload).then((response) => {
        if (response) {
          this.$store.dispatch('Groups/change_current_menu_name', this.client.name);
          this.$buefy.toast.open({
            message: this.$t('add-client.save-client-success'),
            type: 'is-success',
          });
          this.$emit('change');
          this.toggleModal();
        } else {
          this.$buefy.toast.open({
            message: this.$t('add-client.save-client.error'),
            position: 'is-bottom',
            type: 'is-danger',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.add-customer {
  align-self: center;

  .inline-field {
    display: inline-block;
    padding-right: 2rem;
  }

  .color-field {
    width: 18.5rem;
  }

  .upload {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .upload img {
    margin-left: 10px;
    height: fit-content;
    width: 250px;
  }

  .preview {
    font-family: "Noto Sans";
    font-size: 2rem;
    font-weight: 400;
    height: 7rem;
    padding: .5rem;
    border-radius: 15px;
  }

  .subtitle {
    color: black !important;
    font-weight: 450;
  }

  .preview-tab {
    .tabs {
      font-family: "Noto Sans";
      padding-top: 1rem;
      font-size: 1.125rem !important;
      a {
        border-bottom-color: var(--pcolor) !important;
        border-bottom-width: none;
        padding-top: 7.2px !important;
        padding-bottom: 7.2px !important;
      }
      ul {
        border-bottom-color: var(--pcolor) !important;
        border-bottom-width: 3px !important;
        font-weight: 400 !important;
      }
    }
    .b-tabs {
      .tab-content {
        padding: 0 !important;
      }
    }
    .tabs :not(:hover) {
      transition: color 0.25s ease;
      transition: background-color 0.5s ease;
    }
    .tabs a:hover {
      transition: color 0.25s ease;
      transition: background-color 0.5s ease;
      color: var(--pcolortext) !important;
      font-weight: 700 !important;
      background-color: var(--pcolor) !important;
      border-bottom-color: var(--pcolor) !important;
    }
    .tabs li.is-active a {
      color: var(--pcolortext) !important;
      font-weight: 700 !important;
      background-color: var(--pcolor) !important;
      border-bottom-color: var(--pcolor) !important;
    }
  }

  .test-button {
  background-color: var(--scolor) !important;
  color: var(--scolortext) !important;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  margin: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-color: #dbdbdb;
  letter-spacing:1px;
  position: relative;
  text-shadow:0;
  margin: .2rem !important;
  letter-spacing:1px;
  cursor:
    pointer;
    transition: all 0.25s ease;
  }

  .test-button:hover {
    border: 1px solid var(--pcolor) !important;
  }
}
</style>
